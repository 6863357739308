import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./util/config/AppInsights";
import { AppRoutes } from "./util/config/AppRoutes";
import ReactGA from "react-ga4";
import ScrollToTop from "./components/navigation/ScrollToTop";
import { ProfileProvider } from "./util/ProfileContext";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./util/config/MsalConfig";
import { MsalProvider } from "@azure/msal-react";
import { SchemaOrg } from "./components/layouts/SchemaOrg";
import {
  faqSchema,
  organizationSchema,
  softwareSchema,
  websiteSchema,
} from "./util/config/SchemaOrgConfig";

export const SyllabyteAppBaseUrl =
  process.env.REACT_APP_SYLLABYTE_APP_BASE || "";

export const MSALInstance = new PublicClientApplication(msalConfig);

function App(): JSX.Element {
  const queryClient = new QueryClient();

  const insightsKey =
    process.env.REACT_APP_SYLLABYTE_APP_INSIGHTS_INSTRUMENTATION;
  if (!insightsKey) {
    throw new Error("Unable to determine app insights configuration");
  }

  const googleAnalyticsKey =
    process.env.REACT_APP_SYLLABYTE_GOOGLEANALYTICS_MEASUREMENTID;
  if (!googleAnalyticsKey) {
    throw new Error("Unable to determine google analytics configuration");
  }

  ReactGA.initialize(googleAnalyticsKey);

  return (
    <MsalProvider instance={MSALInstance}>
      <ProfileProvider>
        <AppInsightsContext.Provider value={reactPlugin}>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <SchemaOrg
                organization={organizationSchema}
                website={websiteSchema}
                software={softwareSchema}
                faqPage={faqSchema}
              />
              <ReactQueryDevtools initialIsOpen={false} />
              <ScrollToTop />
              <AppRoutes />
            </QueryClientProvider>
          </BrowserRouter>
        </AppInsightsContext.Provider>
      </ProfileProvider>
    </MsalProvider>
  );
}

export default App;
