import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useState } from "react";
import imgSearch from "../../../assets/images/undraw_collecting_re_lp6p.svg";
import { useQueryParameters } from "../../../util/Helpers";
import { getSharedItem } from "../../../util/ApiIntegration";
import { Loading } from "../../../components/Loading";
import { SharedUploadViewer } from "./SharedUploadViewer";
import { SharedContentViewer } from "./SharedContentViewer";
import { ContentSection } from "../../../components/layouts/ContentSection";

export function SharedItemLayout(): JSX.Element {
  const parameters = useParams<{
    ownerId: string;
    itemId: string;
  }>();

  const { ownerId, itemId } = parameters;
  const accessKey = useQueryParameters()?.get("key") || undefined;

  // not found bool in react state
  const [notFound, setNotFound] = useState(false);

  if (!ownerId || !itemId) {
    throw new Error("ownerId and itemId must be provided");
  }

  const { data: sharedItem, isFetching } = useQuery({
    queryKey: [`getShared-${ownerId}-${itemId}`],
    queryFn: async () => {
      try {
        const responseData = await getSharedItem(ownerId, itemId, accessKey);
        return responseData;
      } catch {
        setNotFound(true);
        return;
      }
    },
    refetchOnWindowFocus: false,
  });

  let sharedComponent: JSX.Element = <></>;

  if (sharedItem && !isFetching) {
    switch (sharedItem.type) {
      case "upload": {
        sharedComponent = (
          <SharedUploadViewer
            sharedItem={sharedItem}
            accessKey={accessKey}
          ></SharedUploadViewer>
        );
        break;
      }
      case "lesson": {
        sharedComponent = (
          <SharedContentViewer
            sharedItem={sharedItem}
            accessKey={accessKey}
          ></SharedContentViewer>
        );
        break;
      }

      default: {
        sharedComponent = <div>Unknown shared item type</div>;
        break;
      }
    }
  }

  if (isFetching) {
    sharedComponent = (
      <ContentSection
        h2={"Loading..."}
        bgClasses="mt-12"
        content={<Loading></Loading>}
      />
    );
  }

  if (!isFetching && notFound) {
    sharedComponent = (
      <ContentSection
        h2={"Not Found or You don't have Access"}
        ctaButtonPath="/sharing"
        ctaButtonText="Back to Sharing and Privacy"
        bgClasses="mt-12"
        content={
          <>
            <img
              src={imgSearch}
              alt={"Not Found or You don't have Access"}
              title={"Not Found or You don't have Access"}
              className="w-72 float-right"
            />
            <p>
              We couldn't find what you're looking for, or you don't have access
              to it.
            </p>
            <p>
              If you think this is an error, please contact the owner of the
              item to confirm the link is correct. If you are the owner, please
              check the sharing settings for this item.
            </p>
          </>
        }
      />
    );
  }

  return sharedComponent;
}
