import React, { useState, useRef, useEffect } from "react";
import {
  ChevronUpDownIcon,
  CheckIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ListItem } from "../../types/platform/ListItem";

interface DropdownProperties {
  name: string;
  label?: string;
  helper?: string;
  options: ListItem[];
  value: string;
  disabled?: boolean;
  onChange?: (key: string) => void;
}

export const Dropdown: React.FC<DropdownProperties> = ({
  name,
  label,
  helper,
  options,
  value,
  disabled = false,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownReference = useRef<HTMLDivElement>(null);
  const buttonReference = useRef<HTMLButtonElement>(null);
  const searchInputReference = useRef<HTMLInputElement>(null);
  const selectedOption = options.find(option => option.key === value);

  const filteredOptions = options.filter(
    option =>
      option.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      option.label?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      option.key?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownReference.current &&
        !dropdownReference.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && searchInputReference.current) {
      searchInputReference.current.focus();
    }
  }, [isOpen]);

  const handleSelect = (key: string) => {
    onChange?.(key);
    setIsOpen(false);
    setSearchTerm("");
    buttonReference.current?.focus();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      setSearchTerm("");
      buttonReference.current?.focus();
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    if (searchInputReference.current) {
      searchInputReference.current.focus();
    }
  };

  return (
    <div className="relative w-full" ref={dropdownReference}>
      {label && (
        <label htmlFor={name} className="block font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div>
        <button
          ref={buttonReference}
          type="button"
          className={`
            bg-white relative w-full border rounded-md shadow-sm pl-3 pr-10 py-2 text-left 
            focus:outline-none focus:ring-2 focus:ring-syllabyte-blue focus:border-syllabyte-blue 
            sm:text-sm transition duration-150 ease-in-out
            ${disabled ? "bg-gray-100 cursor-not-allowed" : "cursor-pointer hover:bg-gray-50"} 
            ${isOpen ? "ring-2 ring-syllabyte-blue border-syllabyte-blue" : "border-gray-300"}
          `}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          aria-labelledby={label}
          onClick={() => !disabled && setIsOpen(!isOpen)}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        >
          <span className="flex items-center">
            {selectedOption?.iconPath && (
              <img
                src={selectedOption.iconPath}
                alt=""
                className="flex-shrink-0 h-5 w-5 rounded-md mr-2"
              />
            )}
            <span className="block truncate">
              {selectedOption?.label || "Please Select"}
            </span>
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              className={`h-5 w-5 ${isOpen ? "text-syllabyte-blue" : "text-gray-400"}`}
              aria-hidden="true"
            />
          </span>
        </button>

        {isOpen && (
          <div className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-hidden focus:outline-none sm:text-sm">
            <div className="sticky top-0 bg-white z-10 px-3 py-2 border-b">
              <div className="relative">
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                <input
                  type="text"
                  ref={searchInputReference}
                  className="w-full pl-9 pr-8 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-syllabyte-blue focus:border-syllabyte-blue"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={event => setSearchTerm(event.target.value)}
                  onKeyDown={handleKeyDown}
                />
                {searchTerm && (
                  <button
                    type="button"
                    onClick={handleClear}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-syllabyte-blue"
                    aria-label="Clear search"
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                )}
              </div>
            </div>
            <div className="max-h-72 overflow-auto">
              {filteredOptions.length === 0 ? (
                <div className="px-3 py-2 text-sm text-gray-500">
                  No results found
                </div>
              ) : (
                <ul
                  tabIndex={-1}
                  role="listbox"
                  aria-labelledby={label}
                  aria-activedescendant={selectedOption?.key}
                >
                  {filteredOptions.map(option => (
                    <li
                      key={option.key}
                      className={`
                        cursor-pointer select-none relative py-2 pl-3 pr-9
                        ${
                          option.key === value
                            ? "text-white bg-syllabyte-blue"
                            : "text-gray-900 hover:bg-gray-100"
                        }
                      `}
                      id={option.key}
                      role="option"
                      aria-selected={option.key === value}
                      onClick={() => handleSelect(option.key)}
                    >
                      <div className="flex items-center">
                        {option.iconPath && (
                          <img
                            src={option.iconPath}
                            alt=""
                            className="flex-shrink-0 h-5 w-5 rounded-md mr-2"
                          />
                        )}
                        <span
                          className={`block truncate ${option.key === value ? "font-semibold" : "font-normal"}`}
                        >
                          {option.label}
                        </span>
                      </div>

                      {option.key === value && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-white">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}

        {helper && (
          <p id={`${name}-helper`} className="mt-2 text-sm text-gray-600">
            {helper}
          </p>
        )}
      </div>
    </div>
  );
};
