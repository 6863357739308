export interface FrequentlyAskedQuestion {
  questionText: string;
  content: JSX.Element;
  plainTextAnswer: string;
}

interface FaqProperties {
  bgClasses?: string;
}

export const FAQS: FrequentlyAskedQuestion[] = [
  {
    questionText: "How long does it take to implement Syllabyte.ai?",
    content: (
      <>
        <p>
          With our No-Code SaaS platform, you can start using basic features
          immediately after setup. For enterprise implementations with custom
          workflows and API integrations, our team typically completes full
          deployment within weeks, not months. We provide comprehensive support
          throughout the implementation process to ensure a smooth transition
          for your publishing workflows.
        </p>
      </>
    ),
    plainTextAnswer:
      "With our No-Code SaaS platform, you can start using basic features immediately after setup. For enterprise implementations with custom workflows and API integrations, our team typically completes full deployment within weeks, not months. We provide comprehensive support throughout the implementation process to ensure a smooth transition for your publishing workflows.",
  },
  {
    questionText:
      "How does Syllabyte.ai protect our intellectual property and content privacy?",
    content: (
      <>
        <p>
          We maintain complete data isolation and privacy for all publisher
          content. Our platform runs on private deployments of LLM models,
          ensuring your content never contributes to training data for Meta,
          OpenAI, or other providers. Your intellectual property remains solely
          under your control, with no data ever being used to enhance external
          AI models.
        </p>
      </>
    ),
    plainTextAnswer:
      "We maintain complete data isolation and privacy for all publisher content. Our platform runs on private deployments of LLM models, ensuring your content never contributes to training data for Meta, OpenAI, or other providers. Your intellectual property remains solely under your control, with no data ever being used to enhance external AI models.",
  },
  {
    questionText:
      "What types of educational content can be processed through the platform?",
    content: (
      <>
        <p>
          Our platform supports all major educational content formats including
          complete textbooks, chapters, PDF documents, Office files, audio
          content, video materials, images, graphics, and structured data
          formats. The AI-powered system can analyze and transform any
          educational material while maintaining pedagogical integrity and
          accessibility compliance.
        </p>
      </>
    ),
    plainTextAnswer:
      "Our platform supports all major educational content formats including complete textbooks, chapters, PDF documents, Office files, audio content, video materials, images, graphics, and structured data formats. The AI-powered system can analyze and transform any educational material while maintaining pedagogical integrity and accessibility compliance.",
  },
  {
    questionText:
      "Why doesn't Syllabyte.ai use vector databases for content storage?",
    content: (
      <>
        <p>
          We've intentionally chosen not to use vector databases because they
          typically integrate into model training and enhancements - which
          conflicts with our commitment to content privacy. Our approach
          provides better flexibility in content management, reduces complexity,
          and lowers costs while still delivering excellent multilingual
          semantic search capabilities. If vector storage becomes beneficial in
          the future, our technology stack can easily implement it without
          requiring additional content processing.
        </p>
      </>
    ),
    plainTextAnswer:
      "We've intentionally chosen not to use vector databases because they typically integrate into model training and enhancements - which conflicts with our commitment to content privacy. Our approach provides better flexibility in content management, reduces complexity, and lowers costs while still delivering excellent multilingual semantic search capabilities. If vector storage becomes beneficial in the future, our technology stack can easily implement it without requiring additional content processing.",
  },
  {
    questionText:
      "How can publishers integrate Syllabyte.ai into existing workflows?",
    content: (
      <>
        <p>
          We offer multiple integration paths to suit your needs. You can get
          started immediately with our No-Code SaaS platform through an
          intuitive web interface, or integrate deeply using our REST API. We
          support direct API access, workflow automation tools, batch processing
          capabilities, and seamless integration with existing publishing
          systems. Our platform is built for enterprise-level processing and
          automation.
        </p>
      </>
    ),
    plainTextAnswer:
      "We offer multiple integration paths to suit your needs. You can get started immediately with our No-Code SaaS platform through an intuitive web interface, or integrate deeply using our REST API. We support direct API access, workflow automation tools, batch processing capabilities, and seamless integration with existing publishing systems. Our platform is built for enterprise-level processing and automation.",
  },
  {
    questionText:
      "What AI services does Syllabyte.ai provide for educational content?",
    content: (
      <>
        <p>
          Our comprehensive suite of AI services includes question generation,
          lesson planning, image accessibility analysis, math equation
          conversion, learning outcome generation, text summarization and
          simplification, glossary creation, content alignment to educational
          frameworks (WEF Education 4.0, 21st Century Learning Design), marking
          criteria generation, and much more. All services are designed
          specifically for publishing needs.
        </p>
      </>
    ),
    plainTextAnswer:
      "Our comprehensive suite of AI services includes question generation, lesson planning, image accessibility analysis, math equation conversion, learning outcome generation, text summarization and simplification, glossary creation, content alignment to educational frameworks (WEF Education 4.0, 21st Century Learning Design), marking criteria generation, and much more. All services are designed specifically for publishing needs.",
  },
  {
    questionText:
      "How does Syllabyte.ai help with content accessibility compliance?",
    content: (
      <>
        <p>
          Our AI-powered platform automatically ensures accessibility compliance
          through multiple features including rich ALT text generation for
          images, math equation conversion to LaTeX and MathML, text
          simplification across reading levels, and comprehensive accessibility
          analysis. This automation helps publishers meet accessibility
          standards while significantly reducing manual workflow time.
        </p>
      </>
    ),
    plainTextAnswer:
      "Our AI-powered platform automatically ensures accessibility compliance through multiple features including rich ALT text generation for images, math equation conversion to LaTeX and MathML, text simplification across reading levels, and comprehensive accessibility analysis. This automation helps publishers meet accessibility standards while significantly reducing manual workflow time.",
  },
  {
    questionText: "What cost savings can publishers expect with Syllabyte.ai?",
    content: (
      <>
        <p>
          Based on active implementations, publishers typically see production
          cost reductions of 50% or more through automated workflows, reduced
          development cycles, and eliminated outsourcing dependencies.
          Additionally, our platform accelerates time to market by 70% while
          improving content quality by 40% through AI-powered analysis and
          standardization.
        </p>
      </>
    ),
    plainTextAnswer:
      "Based on active implementations, publishers typically see production cost reductions of 50% or more through automated workflows, reduced development cycles, and eliminated outsourcing dependencies. Additionally, our platform accelerates time to market by 70% while improving content quality by 40% through AI-powered analysis and standardization.",
  },
  {
    questionText:
      "Can Syllabyte.ai handle custom educational standards and frameworks?",
    content: (
      <>
        <p>
          Yes, our platform can align content with any educational standard or
          framework. We support automated standards alignment, gap analysis, and
          custom framework integration. This includes regional standards,
          institutional frameworks, and international educational models like
          WEF Education 4.0 and 21st Century Learning Design.
        </p>
      </>
    ),
    plainTextAnswer:
      "Yes, our platform can align content with any educational standard or framework. We support automated standards alignment, gap analysis, and custom framework integration. This includes regional standards, institutional frameworks, and international educational models like WEF Education 4.0 and 21st Century Learning Design",
  },
  {
    questionText:
      "How does the content adaptation and localization process work?",
    content: (
      <>
        <p>
          Our AI platform automates regional content adaptation and
          market-specific localization while maintaining pedagogical integrity.
          The system can adjust language levels, cultural references, and
          educational standards to match target market requirements. This
          automation significantly reduces the time and cost typically
          associated with content localization.
        </p>
      </>
    ),
    plainTextAnswer:
      "Our AI platform automates regional content adaptation and market-specific localization while maintaining pedagogical integrity. The system can adjust language levels, cultural references, and educational standards to match target market requirements. This automation significantly reduces the time and cost typically associated with content localization.",
  },
  {
    questionText:
      "What makes Syllabyte.ai different from other AI content platforms?",
    content: (
      <>
        <p>
          Syllabyte.ai is specifically built for publishers with a focus on
          content privacy, pedagogical integrity, and publishing workflows. Our
          platform combines private AI model deployments, comprehensive
          publishing-specific services, and flexible integration options while
          ensuring publishers maintain complete control of their intellectual
          property. Unlike general AI platforms, we understand and support the
          unique requirements of content development.
        </p>
      </>
    ),
    plainTextAnswer:
      "Syllabyte.ai is specifically built for publishers with a focus on content privacy, pedagogical integrity, and publishing workflows. Our platform combines private AI model deployments, comprehensive publishing-specific services, and flexible integration options while ensuring publishers maintain complete control of their intellectual property. Unlike general AI platforms, we understand and support the unique requirements of content development.",
  },
];

export const Faq = (properties: FaqProperties): JSX.Element => {
  const { bgClasses } = properties;

  return (
    <section className={`${bgClasses ?? "bg-white py-24"}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center mb-12">
          <h2 className="text-3xl font-bold text-syllabyte-navy mb-6">Faq's</h2>
        </div>

        <div className="mt-12 text-base">
          <dl className="divide-y divide-gray-200">
            {FAQS.map(question => (
              <div
                key={question.questionText}
                className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8"
              >
                <dt className="text-xl font-semibold md:col-span-5">
                  <h3>{question.questionText}</h3>
                </dt>
                <div className="md:mt-0 md:col-span-7">
                  <dd className="prose">{question.content}</dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
};
