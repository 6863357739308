export interface ImageObject {
  "@type": "ImageObject";
  url: string;
  width: string;
  height: string;
}

export interface PriceSpecification {
  "@type": "PriceSpecification";
  price: string;
  priceCurrency: string;
}

export interface SaaSOffer {
  "@type": "SaaS";
  category: string;
  priceSpecification: PriceSpecification;
}

export interface OrganizationSchema {
  "@type": "Organization";
  "@id": string;
  name: string;
  url: string;
  logo: ImageObject;
  sameAs: string[];
}

export interface WebsiteSchema {
  "@type": "WebSite";
  "@id": string;
  url: string;
  name: string;
  description: string;
  publisher: {
    "@id": string;
  };
}

export interface SoftwareApplicationSchema {
  "@type": "SoftwareApplication";
  name: string;
  applicationCategory: string;
  operatingSystem: string;
  description: string;
  offers: SaaSOffer;
  featureList: string[];
  applicationSubCategory: string;
  requirements: string;
  image: string;
}

export interface FAQAnswer {
  "@type": "Answer";
  text: string;
}

export interface FAQQuestion {
  "@type": "Question";
  name: string;
  acceptedAnswer: FAQAnswer;
}

export interface FAQPageSchema {
  "@type": "FAQPage";
  mainEntity: FAQQuestion[];
}

export interface SchemaGraph {
  "@context": "https://schema.org";
  "@graph": (
    | OrganizationSchema
    | WebsiteSchema
    | SoftwareApplicationSchema
    | FAQPageSchema
  )[];
}

interface SchemaOrgProperties {
  organization: OrganizationSchema;
  website: WebsiteSchema;
  software: SoftwareApplicationSchema;
  faqPage?: FAQPageSchema;
}

export const SchemaOrg: React.FC<SchemaOrgProperties> = ({
  organization,
  website,
  software,
  faqPage,
}) => {
  const schemaOrgData: SchemaGraph = {
    "@context": "https://schema.org",
    "@graph": [organization, website, software, ...(faqPage ? [faqPage] : [])],
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(schemaOrgData, undefined, 2),
      }}
    />
  );
};
