import {
  ApplicationInsights,
  ITelemetryPlugin,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import * as history from "history";

const browserHistory = history.createBrowserHistory();
const reactPlugin = new ReactPlugin();
const clickPlugin = new ClickAnalyticsPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.REACT_APP_SYLLABYTE_APP_INSIGHTS_INSTRUMENTATION,
    extensions: [reactPlugin as unknown as ITelemetryPlugin, clickPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPlugin.identifier]: {
        autoCapture: true,
        dataTags: { useDefaultContentNameOrId: true },
      },
    },
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
