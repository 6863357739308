import { useContext, useEffect, useMemo } from "react";
import { ListItem } from "../../types/platform/ListItem";
import { MARKETLIST } from "../../util/MarketsList";
import { ProfileContext } from "../../util/ProfileContext";
import { Dropdown } from "./Dropdown";

interface MarketPickerProperties {
  label: string;
  onChange: (value: ListItem) => void;
  selectedValue?: ListItem;
  autoDetect?: boolean;
}

export const MarketPicker = (
  properties: MarketPickerProperties,
): JSX.Element => {
  const { label, onChange, selectedValue, autoDetect = false } = properties;
  const { profile } = useContext(ProfileContext);

  const filteredMarketList = useMemo(() => {
    // Slava Ukraini
    return MARKETLIST.filter(market => market.key !== "ru-RU");
  }, []);

  useEffect(() => {
    const loadDefaultDefaultMarket = async () => {
      if (profile?.market) {
        // Use filteredMarketList instead of MARKETLIST
        const market = filteredMarketList.find(
          existingMarket => existingMarket.key === profile.market,
        );
        if (market) {
          onChange(market);
        }
        return;
      }

      if (autoDetect) {
        try {
          const response = await fetch("https://ipapi.co/json", {
            mode: "cors",
          });
          const data = await response.json();
          const defaultMarket = data.languages.split(",")[0];
          if (defaultMarket) {
            let market = filteredMarketList.find(
              existingMarket => existingMarket.key === defaultMarket,
            );
            if (!market) {
              market = filteredMarketList.find(
                existingMarket =>
                  existingMarket.key.slice(0, 2) === defaultMarket.slice(0, 2),
              );
            }
            if (market) {
              onChange(market);
            }
          }
        } catch (error) {
          console.error("Failed to fetch location data", error);
        }
      }
    };

    if (!selectedValue || !selectedValue.key || selectedValue.key === "") {
      loadDefaultDefaultMarket();
    }
  }, [profile, autoDetect, selectedValue?.key, onChange, filteredMarketList]);

  return (
    <Dropdown
      label={label}
      name="market"
      options={filteredMarketList}
      onChange={(value: string) => {
        const market = filteredMarketList.find(option => option.key === value);
        if (market) {
          onChange(market);
        }
      }}
      value={selectedValue?.key || ""}
    />
  );
};
