import clsx from "clsx";

export interface BadgeProperties {
  text: string;
  className?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  key?: number | string;
  onClick?: () => void;
}

export function Badge(properties: BadgeProperties): JSX.Element {
  const {
    text,
    className,
    backgroundColor = "bg-syllabyte-navy",
    foregroundColor = "text-white",
    key,
    onClick,
  } = properties;

  return (
    <span
      key={key}
      className={clsx(
        "whitespace-nowrap capitalize inline-block px-2.5 py-0.5 rounded-md text-sm font-medium",
        className,
        backgroundColor,
        foregroundColor,
        onClick && "cursor-pointer hover:opacity-80 transition-opacity",
      )}
      onClick={onClick}
    >
      {text}
    </span>
  );
}
