import { Popover } from "@headlessui/react";
import { ChevronDownIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { NavItem } from "../../types/platform/NavItem";
import { NavLinkItem } from "./NavLinkItem";
import { defaultLinkStyle, mobileLinkStyle } from "./NavBar";

export const FlyoutItem: React.FC<{
  item: NavItem;
  onClose: () => void;
  isMobile: boolean;
}> = React.memo(({ item, onClose, isMobile }) => {
  const location = useLocation();
  const isChildActive = item.flyout?.navItems.some(
    flyoutItem =>
      flyoutItem.path && location.pathname.startsWith(flyoutItem.path),
  );

  if (isMobile) {
    return (
      <div>
        <p
          className={clsx(
            mobileLinkStyle,
            isChildActive ? "text-syllabyte-blue font-bold" : "",
          )}
        >
          {item.label}
        </p>
        {item.flyout?.navItems.map(flyoutItem => (
          <NavLinkItem
            key={flyoutItem.label}
            item={flyoutItem}
            onClick={onClose}
            className={clsx(mobileLinkStyle, "pl-8")}
          />
        ))}
      </div>
    );
  }

  return (
    <Popover className="relative">
      {({ open, close: closePopover }) => (
        <>
          <Popover.Button
            className={clsx(
              defaultLinkStyle,
              "group inline-flex items-center",
              isChildActive || open
                ? "border-syllabyte-blue text-syllabyte-blue"
                : "",
            )}
          >
            <span>{item.label}</span>
            <ChevronDownIcon
              className={clsx(
                "ml-2 h-5 w-5 transition-transform",
                open ? "rotate-180 text-syllabyte-blue" : "text-syllabyte-navy",
              )}
              aria-hidden="true"
            />
          </Popover.Button>
          <Popover.Panel className="absolute z-20 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                {item.flyout?.navItems.map(flyoutItem => (
                  <NavLink
                    end
                    to={flyoutItem.path || ""}
                    onClick={() => {
                      onClose();
                      closePopover();
                    }}
                    key={flyoutItem.label}
                    className={({ isActive }) =>
                      clsx(
                        "-m-3 p-5 cursor-pointer flex items-start rounded-lg transition ease-in-out duration-150",
                        isActive
                          ? "bg-syllabyte-blue bg-opacity-10"
                          : "hover:bg-gray-100",
                      )
                    }
                  >
                    <img
                      className="w-16 -ml-4 -mr-1 -mt-1 float-left"
                      src={flyoutItem.imageSrc}
                      alt={flyoutItem.label}
                    />
                    <div className="ml-4">
                      <p
                        className={clsx(
                          "font-medium",
                          location.pathname.startsWith(flyoutItem.path || "")
                            ? "text-syllabyte-blue"
                            : "",
                        )}
                      >
                        {flyoutItem.label}
                      </p>
                      <p className="mt-1 text-sm text-gray-600">
                        {flyoutItem.description}
                      </p>
                    </div>
                  </NavLink>
                ))}
              </div>
              <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0">
                <div className="flow-root w-full">
                  <NavLink
                    end
                    onClick={() => {
                      onClose();
                      closePopover();
                    }}
                    to={item.flyout?.footerPath || ""}
                    className="-m-3 p-3 flex items-center rounded-md font-medium hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <ArrowRightIcon
                      className="flex-shrink-0 h-6 w-5 text-gray-600"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{item.flyout?.footerText}</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
});
FlyoutItem.displayName = "FlyoutItem";
