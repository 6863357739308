import { Button } from "./fields/Button";
import { ProfileContext } from "../util/ProfileContext";
import {
  Transition,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from "@headlessui/react";
import { useState, Fragment, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AiFace, AiFaceType } from "./AiFace";
import { CountDownTimer } from "./CountDownTimer";
import ReactGA from "react-ga4";
import imgAi from "../assets/images/undraw_firmware_re_fgdy.svg";
import imgOffline from "../assets/images/ai-face/ai-face-offline.svg";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const UpSellContainer = (): JSX.Element => {
  const {
    platformLimit,
    updatePlatformLimit,
    hitPlatformLimit,
    hasSubscription,
  } = useContext(ProfileContext);

  const isOnProfile: boolean = useLocation().pathname === "/profile";

  /* handle time out event, set a recurring check for platform limit */
  const handleTimeExpired = (): void => {
    updatePlatformLimit();
  };

  const [openLimitModal, setOpenLimitModal] = useState<boolean>(false);

  useEffect(() => {
    if (hitPlatformLimit) {
      setOpenLimitModal(true);
    }
  }, [platformLimit, hitPlatformLimit]);

  const handleTellMeMore = () => {
    setOpenLimitModal(true);
    ReactGA.event("clicked-tell-me-more", "clicked-tell-me-more");
  };

  useEffect(() => {
    // Define the handler
    const handleTooManyRequests = () => {
      if (hitPlatformLimit) {
        if (hasSubscription) {
          ReactGA.event("paid-platform-limit-hit", "platform-limit");
        } else {
          ReactGA.event("free-platform-limit-hit", "platform-limit");
        }
      }

      setOpenLimitModal(true);
    };

    // Add the event listener
    window.addEventListener(
      "tooManyRequests",
      handleTooManyRequests as EventListener,
    );

    // Remove the event listener on cleanup
    return () => {
      window.removeEventListener(
        "tooManyRequests",
        handleTooManyRequests as EventListener,
      );
    };
  }, []);

  return (
    <>
      {(!hasSubscription || isOnProfile) && (
        <div className="relative bg-syllabyte-green bg-opacity-5 border">
          <div className="max-w-7xl flex justify-between mx-auto xs:px-4 sm:px-8">
            <div className="xs:pl-2 sm:pl-16 pt-2 flex flex-col justify-end">
              {!platformLimit && <AiFace type={AiFaceType.On}></AiFace>}
              {platformLimit && (
                <AiFace
                  type={
                    platformLimit.consumed >= platformLimit.max
                      ? AiFaceType.Offline
                      : AiFaceType.Happy
                  }
                ></AiFace>
              )}
            </div>
            <div className="flex items-center">
              <div className="flex-1 ml-3">
                <div className="py-2">
                  <p className="text-right leading-7">
                    {!platformLimit && (
                      <span>Your robot is ready and standing by...</span>
                    )}
                    {platformLimit && (
                      <span className="text-sm">
                        Your robot can perform{" "}
                        <span className="font-medium text-base">
                          {platformLimit.max - platformLimit.consumed}
                        </span>{" "}
                        more actions this shift.{" "}
                        <CountDownTimer
                          dateTime={platformLimit.resetTimeUtc}
                          onTimeUp={handleTimeExpired}
                        />{" "}
                        until shift change.
                      </span>
                    )}{" "}
                    <span
                      onClick={handleTellMeMore}
                      className="text-sm underline hover:cursor-pointer hover:text-syllabyte-blue"
                    >
                      tell me more
                    </span>
                    {!hasSubscription && !hitPlatformLimit && (
                      <Button
                        eventAction="clicked-upsell-upgrade"
                        to={"/pricing"}
                        variant="syllabyteGreen"
                        className="ml-2 xs:mt-2 md:mt-0 xs:mb-2 md:mb-0"
                      >
                        Upgrade Robot
                      </Button>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {hitPlatformLimit && (
            <div className="max-w-7xl text-center pb-4 mx-auto xs:px-4 sm:px-8">
              <hr />
              <h3 className="text-center pt-8 pb-2 font-medium text-2xl text-syllabyte-green">
                Your robot needs a rest...
              </h3>
              <p className="pb-4">
                We've sent your robot to the break room for now. If you need to
                get going again fast, it might be time for an upgrade?
              </p>

              <Button
                eventAction="clicked-upsell-upgrade"
                to={"/pricing"}
                variant="syllabyteGreen"
                className="xs:mt-2 md:mt-0 mb-6"
              >
                Upgrade Options
              </Button>
            </div>
          )}
        </div>
      )}

      <>
        <Transition.Root show={openLimitModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => {
              setOpenLimitModal(false);
            }}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                    <div className="float-right pt-2 pr-2">
                      <Button
                        variant="round"
                        onClick={() => setOpenLimitModal(false)}
                      >
                        <XMarkIcon className="h-6 w-6 text-gray-500" />
                      </Button>
                    </div>
                    <div className="bg-white p-4 text-center">
                      <div className="mt-3 sm:mt-0">
                        <DialogTitle
                          as="h2"
                          className="text-3xl mb-4 w-full p-2 text-center"
                        >
                          {hitPlatformLimit
                            ? "Your robot has hit the limit!"
                            : "So how does this work?"}
                          <br />
                        </DialogTitle>
                      </div>
                      <div>
                        <img
                          loading="lazy"
                          className="mx-auto pb-4 px-4 md:h-auto md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg"
                          src={hitPlatformLimit ? imgOffline : imgAi}
                          alt=""
                        />
                        <p>
                          To ensure all users can enjoy the complete features of
                          teachology.ai, we use robots with varying
                          capabilities, each able to perform a specific number
                          of actions daily.
                        </p>
                        <p className="pt-2">
                          Once you exceed the limit, your robot will need to
                          rest, or you can upgrade to a stronger robot to get
                          going again instantly.
                        </p>
                        <hr className="my-4" />
                        <div className="">
                          <p className="font-medium text-lg mb-2">
                            How does your robot spend actions?
                          </p>
                          <ul className="text-left list-disc ml-6 text-sm">
                            <li>
                              Creating Lessons, Assessments, Units of Work or
                              Feedback
                            </li>
                            <li>Using the Studio</li>
                            <li>Uploading and unpacking files</li>
                            <li>Using the API</li>
                          </ul>
                        </div>
                        <hr className="my-4" />
                        <Button
                          eventCategory="UpSellBanner"
                          eventLabel="UpSellSecondary"
                          eventAction={`Clicked-${location}-Upsell`}
                          to={"/pricing"}
                          variant="syllabyteGreen"
                          className="w-full"
                        >
                          Upgrade Options
                        </Button>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    </>
  );
};
