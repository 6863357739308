import React, { useContext, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Disclosure, DisclosureButton } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavItem } from "../../types/platform/NavItem";
import { Button } from "../fields/Button";
import { ProfileContext } from "../../util/ProfileContext";
import { FlyoutItem } from "./FlyoutItem";
import { NavLinkItem } from "./NavLinkItem";
import {
  BOOKING_FORM_URL,
  SYLLABYTE_LOGO_DARK_URL,
} from "../../util/Constants";

interface NavBarProperties {
  navItems: NavItem[];
}

export const defaultLinkStyle =
  "bg-opacity-0 text-center text-md border-gray-50 hover:border-syllabyte-blue border-b-2 font-medium px-1 py-1 mb-1";
export const mobileLinkStyle =
  "bg-opacity-0 text-md block font-medium rounded-md bg-white px-3 py-2 hover:bg-opacity-10";

export const NavBar: React.FC<NavBarProperties> = React.memo(({ navItems }) => {
  const { profile, refreshProfile } = useContext(ProfileContext);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/dashboard" && !profile) {
      refreshProfile();
    }
  }, [location.pathname, profile, refreshProfile]);

  const renderNavItems = useCallback(
    (isMobile: boolean, close: () => void) =>
      navItems.map(item => {
        if (item.path) {
          return (
            <NavLinkItem
              key={item.label}
              item={item}
              onClick={close}
              className={isMobile ? mobileLinkStyle : defaultLinkStyle}
            />
          );
        }
        if (item.flyout) {
          return (
            <FlyoutItem
              key={item.label}
              item={item}
              onClose={close}
              isMobile={isMobile}
            />
          );
        }
        return <div key={item.label}>Invalid Nav Item</div>;
      }),
    [navItems],
  );

  return (
    <Disclosure as="nav" className="">
      {({ open, close }) => (
        <>
          <div className="max-w-7xl mx-auto xs:px-4 sm:px-8">
            <div className="flex items-center justify-between h-20">
              <div className="flex items-center">
                <div className="flex-shrink-0 mr-6">
                  <Link data-id="nav-logo-home" to="/" onClick={() => close}>
                    <img
                      className="h-12 sm:h-14 w-auto"
                      src={SYLLABYTE_LOGO_DARK_URL}
                      alt="syllabyte.ai"
                    />
                  </Link>
                </div>
              </div>
              <div className="flex">
                <div className="hidden md:block">
                  <div className="flex space-x-4 mr-6">
                    {renderNavItems(false, close)}
                  </div>
                </div>
                <Button
                  to={BOOKING_FORM_URL}
                  className="text-center"
                  loadingColor="white"
                  variant="syllabyteBlue"
                  onClick={close}
                >
                  Book a Demo
                </Button>
                {/* 
                {profile ? (
                  <ProfileMenu
                    isDropDown={true}
                    profile={profile}
                    onClose={close}
                  />
                ) : (
                  <Button
                    to="/login"
                    className="text-center"
                    isLoading={isLoading}
                    loadingColor="white"
                    variant="syllabyteBlue"
                    onClick={close}
                  >
                    {isLoading ? "Connecting..." : "Login / Register"}
                  </Button>
                )}
                  */}
              </div>
              <div className="-mr-2 flex md:hidden">
                <DisclosureButton className="inline-flex items-center justify-center p-2 rounded-md text-syllabyte-blue hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-syllabyte-blue">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden">
            {/*
            <div className="px-2 pt-2 pb-3 space-y-1">
              {renderNavItems(true, close)}
              {!profile && (
                <Link
                  to="/login"
                  className="cursor-pointer text-center ml-6 xs:ml-0 w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium bg-white hover:bg-indigo-50"
                  onClick={() => close}
                >
                  Login / Register
                </Link>
              )}
            </div>
            {profile && (
              <ProfileMenu
                isDropDown={false}
                profile={profile}
                onClose={close}
              />
            )}
               */}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
});
NavBar.displayName = "NavBar";
