import axios, { AxiosError } from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { MSALInstance } from "../../App";
import { loginRequest } from "./MsalConfig";

export const syllabyteAxios = axios.create({
  baseURL: process.env.REACT_APP_SYLLABYTE_API_BASE,
  paramsSerializer: {
    indexes: true,
  },
});

export interface ValidationError {
  propertyName: string;
  errorMessage: string;
  attemptedValue: string;
  severity: number;
  errorCode: string;
  formattedMessagePlaceholderValues: {
    propertyName: string;
    propertyValue: string;
    propertyPath: string;
  };
}

export class ApiError extends Error {
  public readonly statusCode: number;

  public readonly validationErrors?: ValidationError[];

  constructor(
    statusCode: number,
    message: string,
    validationErrors?: ValidationError[],
  ) {
    super(message);
    this.name = "ApiError";
    this.statusCode = statusCode;
    this.validationErrors = validationErrors;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

syllabyteAxios.interceptors.request.use(
  async config => {
    const accounts = MSALInstance.getAllAccounts();
    if (!accounts || accounts.length === 0) {
      // no accounts in play so just return the config;
      return config;
    }

    await MSALInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
      .then(tokenResponse => {
        config.headers.Authorization = "Bearer " + tokenResponse.accessToken;
        return config;
      })
      .catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          console.log(
            "an axios request failed with an InteractionRequiredAuthError, logging out to refresh account",
          );
          MSALInstance.logoutRedirect({ account: accounts[0] });
        }
      });

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

syllabyteAxios.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (error.response) {
      const { status, data } = error.response;

      switch (status) {
        case 401: {
          if (window.location.pathname !== "/login") {
            localStorage.setItem("lastUrl", window.location.href);
          }
          console.log("Unauthenticated. Logging Out", error);
          MSALInstance.logoutRedirect();

          break;
        }
        case 403: {
          window.location.href = "/pricing";

          break;
        }
        case 429: {
          const event = new CustomEvent("tooManyRequests", {
            detail: { message: "Too Many Requests" },
          });
          window.dispatchEvent(event);

          break;
        }
        default: {
          if (status === 400 && Array.isArray(data)) {
            return Promise.reject(
              new ApiError(
                status,
                "Validation Error",
                data as ValidationError[],
              ),
            );
          }
        }
      }

      return Promise.reject(
        new ApiError(status, error.message || "An error occurred"),
      );
    }

    return Promise.reject(new ApiError(500, "An unexpected error occurred"));
  },
);

export interface TooManyRequestsResponseEvent {
  message?: string;
}
