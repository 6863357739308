import { ListItem } from "../types/platform/ListItem";
import AR from "../assets/images/flags/AR.svg";
import AU from "../assets/images/flags/AU.svg";
import AT from "../assets/images/flags/AT.svg";
import BE from "../assets/images/flags/BE.svg";
import BR from "../assets/images/flags/BR.svg";
import CA from "../assets/images/flags/CA.svg";
import CL from "../assets/images/flags/CL.svg";
import DK from "../assets/images/flags/DK.svg";
import FI from "../assets/images/flags/FI.svg";
import FR from "../assets/images/flags/FR.svg";
import DE from "../assets/images/flags/DE.svg";
import HK from "../assets/images/flags/HK.svg";
import IN from "../assets/images/flags/IN.svg";
import ID from "../assets/images/flags/ID.svg";
import IT from "../assets/images/flags/IT.svg";
import JP from "../assets/images/flags/JP.svg";
import KR from "../assets/images/flags/KR.svg";
import MY from "../assets/images/flags/MY.svg";
import MX from "../assets/images/flags/MX.svg";
import NL from "../assets/images/flags/NL.svg";
import NZ from "../assets/images/flags/NZ.svg";
import NO from "../assets/images/flags/NO.svg";
import CN from "../assets/images/flags/CN.svg";
import PL from "../assets/images/flags/PL.svg";
import PH from "../assets/images/flags/PH.svg";
import ZA from "../assets/images/flags/ZA.svg";
import ES from "../assets/images/flags/ES.svg";
import SE from "../assets/images/flags/SE.svg";
import CH from "../assets/images/flags/CH.svg";
import TW from "../assets/images/flags/TW.svg";
import TR from "../assets/images/flags/TR.svg";
import GB from "../assets/images/flags/GB.svg";
import US from "../assets/images/flags/US.svg";
import HR from "../assets/images/flags/HR.svg";
import CZ from "../assets/images/flags/CZ.svg";
import HU from "../assets/images/flags/HU.svg";
import UA from "../assets/images/flags/UA.svg";
import RO from "../assets/images/flags/RO.svg";
import SK from "../assets/images/flags/SK.svg";
import SI from "../assets/images/flags/SI.svg";
import PT from "../assets/images/flags/PT.svg";
import TH from "../assets/images/flags/TH.svg";
import VN from "../assets/images/flags/VN.svg";
import IL from "../assets/images/flags/IL.svg";
import NG from "../assets/images/flags/NG.svg";
import GR from "../assets/images/flags/GR.svg";
import KE from "../assets/images/flags/KE.svg";

export const MARKETLIST: ListItem[] = [
  {
    description: "Argentina",
    label: "Argentina - Spanish",
    key: "es-AR",
    iconPath: AR,
  },
  {
    description: "Australia",
    label: "Australia - English",
    key: "en-AU",
    iconPath: AU,
  },
  {
    description: "Austria",
    label: "Austria - German",
    key: "de-AT",
    iconPath: AT,
  },
  {
    description: "Belgium",
    label: "Belgium - Dutch",
    key: "nl-BE",
    iconPath: BE,
  },
  {
    description: "Belgium",
    label: "Belgium - French",
    key: "fr-BE",
    iconPath: BE,
  },
  {
    description: "Brazil",
    label: "Brazil - Portuguese",
    key: "pt-BR",
    iconPath: BR,
  },
  {
    description: "Canada",
    label: "Canada - English",
    key: "en-CA",
    iconPath: CA,
  },
  {
    description: "Canada",
    label: "Canada - French",
    key: "fr-CA",
    iconPath: CA,
  },
  {
    description: "Chile",
    label: "Chile - Spanish",
    key: "es-CL",
    iconPath: CL,
  },
  {
    description: "Croatia",
    label: "Croatia - Croatian",
    key: "hr",
    iconPath: HR,
  },
  {
    description: "Czech Republic",
    label: "Czech Republic - Czech",
    key: "cs",
    iconPath: CZ,
  },
  {
    description: "Denmark",
    label: "Denmark - Danish",
    key: "da-DK",
    iconPath: DK,
  },
  {
    description: "Finland",
    label: "Finland - Finnish",
    key: "fi-FI",
    iconPath: FI,
  },
  {
    description: "France",
    label: "France - French",
    key: "fr-FR",
    iconPath: FR,
  },
  {
    description: "Germany",
    label: "Germany - German",
    key: "de-DE",
    iconPath: DE,
  },
  { description: "Greece", label: "Greece - Greek", key: "el", iconPath: GR },
  {
    description: "Hong Kong SAR",
    label: "Hong Kong SAR - Traditional Chinese",
    key: "zh-HK",
    iconPath: HK,
  },
  {
    description: "Hungary",
    label: "Hungary - Hungarian",
    key: "hu",
    iconPath: HU,
  },
  {
    description: "India",
    label: "India - English",
    key: "en-IN",
    iconPath: IN,
  },
  {
    description: "India",
    label: "India - Hindi",
    key: "hi",
    iconPath: IN,
  },
  { description: "Israel", label: "Israel - Hebrew", key: "he", iconPath: IL },
  {
    description: "Indonesia",
    label: "Indonesia - English",
    key: "en-ID",
    iconPath: ID,
  },
  {
    description: "Italy",
    label: "Italy - Italian",
    key: "it-IT",
    iconPath: IT,
  },
  {
    description: "Japan",
    label: "Japan - Japanese",
    key: "ja-JP",
    iconPath: JP,
  },
  { description: "Kenya", label: "Kenya - Swahili", key: "sw", iconPath: KE },
  { description: "Korea", label: "Korea - Korean", key: "ko-KR", iconPath: KR },
  {
    description: "Malaysia",
    label: "Malaysia - English",
    key: "en-MY",
    iconPath: MY,
  },
  {
    description: "Malaysia",
    label: "Malaysia - Malay",
    key: "MY",
    iconPath: MY,
  },
  {
    description: "Mexico",
    label: "Mexico - Spanish",
    key: "es-MX",
    iconPath: MX,
  },
  {
    description: "Netherlands",
    label: "Netherlands - Dutch",
    key: "nl-NL",
    iconPath: NL,
  },
  {
    description: "New Zealand",
    label: "New Zealand - English",
    key: "en-NZ",
    iconPath: NZ,
  },
  {
    description: "Nigeria",
    label: "Nigeria - Yoruba",
    key: "NG",
    iconPath: NG,
  },
  {
    description: "Norway",
    label: "Norway - Norwegian",
    key: "no-NO",
    iconPath: NO,
  },
  {
    description: "People's republic of China",
    label: "People's republic of China - Chinese",
    key: "zh-CN",
    iconPath: CN,
  },
  {
    description: "Poland",
    label: "Poland - Polish",
    key: "pl-PL",
    iconPath: PL,
  },
  {
    description: "Portugal",
    label: "Portugal - Portuguese",
    key: "pt-PT",
    iconPath: PT,
  },
  {
    description: "Philippines",
    label: "Philippines - English",
    key: "en-PH",
    iconPath: PH,
  },
  {
    description: "Philippines",
    label: "Philippines - Filipino",
    key: "fil",
    iconPath: PH,
  },
  {
    description: "Romania",
    label: "Romania - Romanian",
    key: "ro",
    iconPath: RO,
  },
  {
    description: "Russia",
    label: "Russia - Russian",
    key: "ru-RU",
    iconPath: UA,
  },
  {
    description: "Slovakia",
    label: "Slovakia - Slovak",
    key: "sk",
    iconPath: SK,
  },
  {
    description: "Slovenia",
    label: "Slovenia - Slovenian",
    key: "sl",
    iconPath: SI,
  },
  {
    description: "South Africa",
    label: "South Africa - English",
    key: "en-ZA",
    iconPath: ZA,
  },
  {
    description: "Spain",
    label: "Spain - Spanish",
    key: "es-ES",
    iconPath: ES,
  },
  {
    description: "Sweden",
    label: "Sweden - Swedish",
    key: "sv-SE",
    iconPath: SE,
  },
  {
    description: "Switzerland",
    label: "Switzerland - French",
    key: "fr-CH",
    iconPath: CH,
  },
  {
    description: "Switzerland",
    label: "Switzerland - German",
    key: "de-CH",
    iconPath: CH,
  },
  {
    description: "Taiwan",
    label: "Taiwan - Traditional Chinese",
    key: "zh-TW",
    iconPath: TW,
  },
  {
    description: "Thailand",
    label: "Thailand - Thai",
    key: "th",
    iconPath: TH,
  },
  {
    description: "Turkey",
    label: "Turkey - Turkish",
    key: "tr-TR",
    iconPath: TR,
  },
  {
    description: "United Kingdom",
    label: "United Kingdom - English",
    key: "en-GB",
    iconPath: GB,
  },
  {
    description: "United States",
    label: "United States - English",
    key: "en-US",
    iconPath: US,
  },
  {
    description: "United States",
    label: "United States - Spanish",
    key: "es-US",
    iconPath: US,
  },
  {
    description: "Ukraine",
    label: "Ukraine - Ukrainian",
    key: "uk",
    iconPath: UA,
  },
  {
    description: "Vietnam",
    label: "Vietnam - Vietnamese",
    key: "vi",
    iconPath: VN,
  },
];
