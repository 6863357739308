import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  DocumentIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { Badge } from "../../components/badges/Badge";
import { ProgressBar } from "../../components/fields/ProgressBar";
import { getStateLabel, getTaskLabel, Upload } from "../../types/Upload";
import { getUploadThumbnails } from "../../util/ApiIntegration";
import { Loading } from "../../components/Loading";
import { useQuery } from "@tanstack/react-query";
import {
  formatDateToShortDisplay,
  formatSecondsDuration,
} from "../../util/Helpers";
import { ThumbnailImage } from "../../components/ThumbnailImage";
import { FileIcon } from "../../components/FileIcon";

interface UploadCardProperties {
  upload: Upload;
  variant?: "default" | "thin";
  onSelect?: () => void;
}

const UploadingCard = ({
  upload,
  variant = "default",
}: UploadCardProperties) => {
  const [sizeInMB, setSizeInMB] = useState<string>("");

  useEffect(() => {
    if (upload.byteSize && upload.byteSize > 0) {
      setSizeInMB(((upload.byteSize || 0) / (1024 * 1024)).toFixed(2) + " MB");
    }
  }, [upload]);

  const cardClasses =
    variant === "thin"
      ? "bg-white rounded-lg shadow-sm overflow-hidden mb-2 p-4"
      : "bg-white rounded-xl shadow-md overflow-hidden mb-4 p-6";

  return (
    <div className={cardClasses}>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div>
            <p
              className="font-bold text-black cursor-not-allowed"
              title="Upload in progress"
            >
              {upload.title || "Uploading..."}
            </p>
            <div className="flex gap-2 mt-2">
              <Badge text={getTaskLabel(upload.task)} />
              <Badge text={getStateLabel(upload.state || "")} />
            </div>
          </div>
          {sizeInMB && (
            <span className="text-gray-500 text-sm">{sizeInMB}</span>
          )}
        </div>

        <div className="space-y-2">
          <p className="text-sm text-gray-600">
            {upload.message || "Uploading file..."}
          </p>
          <ProgressBar
            progress={upload.progressPercent || 0}
            max={100}
            paused={false}
          />
        </div>
      </div>
    </div>
  );
};

const ProcessingCard = ({
  upload,
  variant = "default",
}: UploadCardProperties) => {
  const cardReference = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [metadata, setMetadata] = useState({
    duration: "",
    sizeInMB: "",
    lastUpdated: "",
  });

  const { data: thumbnail, isLoading: isLoadingThumbnail } = useQuery({
    queryKey: ["thumbnail", upload.id],
    queryFn: async () => {
      if (!upload.id) return;
      const thumbnails = await getUploadThumbnails(upload.id, 1);
      return thumbnails[0] ?? "";
    },
    enabled: !!upload.id && upload.state === "complete",
    staleTime: Number.POSITIVE_INFINITY,
    gcTime: 1000 * 60 * 60,
  });

  useEffect(() => {
    if (!upload) return;

    const meta = {
      duration: "",
      sizeInMB: "",
      lastUpdated: "",
    };

    if (upload.byteSize && upload.byteSize > 0) {
      meta.sizeInMB =
        ((upload.byteSize || 0) / (1024 * 1024)).toFixed(2) + " MB";
    }

    if (upload.durationSeconds && upload.durationSeconds > 0) {
      meta.duration = formatSecondsDuration(upload.durationSeconds);
    }

    if (
      upload.lastUpdated &&
      upload.lastUpdated.toString() !== "0001-01-01T00:00:00"
    ) {
      meta.lastUpdated = formatDateToShortDisplay(new Date(upload.lastUpdated));
    }

    setMetadata(meta);
  }, [upload]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 },
    );

    if (cardReference.current) {
      observer.observe(cardReference.current);
    }

    return () => {
      if (cardReference.current) {
        observer.unobserve(cardReference.current);
      }
    };
  }, []);

  const renderThumbnail = () => {
    const containerClasses =
      variant === "thin"
        ? "w-24 h-24 flex-shrink-0"
        : "h-48 w-full md:w-48 flex-shrink-0";

    return (
      <div
        className={`${containerClasses} flex items-center gap-2 justify-center overflow-hidden`}
      >
        {upload.state === "complete" ? (
          isLoadingThumbnail ? (
            <Loading />
          ) : thumbnail && isVisible ? (
            <ThumbnailImage
              src={thumbnail}
              alt={upload.title || ""}
              variant={variant}
            />
          ) : (
            <FileIcon
              fileExtension={upload.fileExtension || ""}
              className={variant === "thin" ? "h-8 w-8" : "h-12 w-12"}
            />
          )
        ) : upload.state === "broken" ? (
          <div className="text-center">
            <ExclamationTriangleIcon
              className={`${variant === "thin" ? "h-8 w-8" : "h-12 w-12"} text-syllabyte-green mx-auto`}
            />
            <p className="text-sm text-syllabyte-green mt-2">Error</p>
          </div>
        ) : (
          <div className="text-center">
            <DocumentIcon
              className={`${variant === "thin" ? "h-8 w-8" : "h-12 w-12"} text-gray-400 mx-auto`}
            />
            <p className="text-sm text-gray-500 mt-2">Processing...</p>
          </div>
        )}
      </div>
    );
  };

  const cardClasses =
    variant === "thin"
      ? "bg-white rounded-lg shadow-sm overflow-hidden mb-2 hover:bg-gray-50 transition-colors"
      : "bg-white rounded-xl shadow-md overflow-hidden mb-4";

  const contentClasses =
    variant === "thin" ? "flex items-center p-2" : "md:flex";

  return (
    <div id={upload.id} ref={cardReference} className={cardClasses}>
      <div className={contentClasses}>
        <div
          className={
            variant === "thin"
              ? "flex-shrink-0 bg-gray-200"
              : "md:flex-shrink-0 bg-gray-200"
          }
        >
          {renderThumbnail()}
        </div>
        <div
          className={
            variant === "thin" ? "ml-4 flex-grow min-w-0" : "p-6 flex-grow"
          }
        >
          <div className="flex gap-2 items-baseline mb-2">
            {upload.task !== "upload" && upload.state !== "complete" && (
              <>
                <Badge text={getTaskLabel(upload.task)} />
                <Badge text={getStateLabel(upload.state || "")} />
              </>
            )}
            {upload.enabled && <Badge text="Enabled" />}
            {upload.isbn && <Badge text={upload.isbn} />}
            {upload.doi && <Badge text={upload.doi} />}
          </div>

          {variant === "default" && (
            <div className="text-gray-500 text-xs uppercase space-x-2">
              {upload.fileExtension && <span>{upload.fileExtension}</span>}
              {metadata.sizeInMB && <span>{metadata.sizeInMB}</span>}
              {metadata.lastUpdated && <span>{metadata.lastUpdated}</span>}
              {metadata.duration && <span>{metadata.duration}</span>}
            </div>
          )}

          <Link
            to={`/uploads/editor/${upload.id}`}
            className={`block font-bold text-black hover:underline ${
              variant === "thin" ? "text-base truncate" : "text-xl mb-2"
            }`}
          >
            {upload.title || "No Title"}
          </Link>

          {upload.description && (
            <p
              className={`text-sm ${variant === "thin" ? "text-gray-500 truncate" : "mt-2 mb-2"}`}
            >
              {variant === "thin"
                ? upload.description
                : upload.description.length > 220
                  ? `${upload.description.slice(0, 220)}...`
                  : upload.description}
            </p>
          )}

          {variant === "default" && upload.state !== "complete" && (
            <div className="mt-4">
              <p className="text-sm mb-2">{upload.message}</p>
              {upload.state !== "broken" && (
                <ProgressBar
                  progress={upload.progressPercent || 0}
                  max={100}
                  paused={false}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export function UploadCard(properties: UploadCardProperties): JSX.Element {
  const isUploading =
    properties.upload.task === "upload" &&
    (properties.upload.state === "ready" ||
      properties.upload.state === "inProgress");

  return isUploading ? (
    <UploadingCard {...properties} />
  ) : (
    <ProcessingCard {...properties} />
  );
}
