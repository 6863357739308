import {
  OrganizationSchema,
  FAQPageSchema,
  WebsiteSchema,
  SoftwareApplicationSchema,
} from "../../components/layouts/SchemaOrg";
import { FAQS, FrequentlyAskedQuestion } from "../../features/home/Faq";

export const organizationSchema: OrganizationSchema = {
  "@type": "Organization",
  "@id": `${process.env.REACT_APP_SYLLABYTE_APP_BASE}/#organization`,
  name: "SyllabyteAI, INC.",
  url: process.env.REACT_APP_SYLLABYTE_APP_BASE ?? "",
  logo: {
    "@type": "ImageObject",
    url: "https://assets.syllabyte.ai/images/syllabyte-ai-logo.png",
    width: "600",
    height: "600",
  },
  sameAs: ["https://www.linkedin.com/company/syllabyteai"],
};

export const faqSchema: FAQPageSchema = {
  "@type": "FAQPage",
  mainEntity: FAQS.map((faq: FrequentlyAskedQuestion) => ({
    "@type": "Question",
    name: faq.questionText,
    acceptedAnswer: {
      "@type": "Answer",
      text: faq.plainTextAnswer,
    },
  })),
};

export const websiteSchema: WebsiteSchema = {
  "@type": "WebSite",
  "@id": `${process.env.REACT_APP_SYLLABYTE_APP_BASE}/#website`,
  url: process.env.REACT_APP_SYLLABYTE_APP_BASE ?? "",
  name: process.env.REACT_APP_META_TITLE ?? "",
  description: process.env.REACT_APP_META_DESCRIPTION ?? "",
  publisher: {
    "@id": `${process.env.REACT_APP_SYLLABYTE_APP_BASE}/#organization`,
  },
};

export const softwareSchema: SoftwareApplicationSchema = {
  "@type": "SoftwareApplication",
  name: process.env.REACT_APP_META_TITLE ?? "",
  applicationCategory: "BusinessApplication",
  operatingSystem: "Web",
  description: process.env.REACT_APP_META_DESCRIPTION ?? "",
  offers: {
    "@type": "SaaS",
    category: "Enterprise AI Solutions",
    priceSpecification: {
      "@type": "PriceSpecification",
      price: "Contact for pricing",
      priceCurrency: "USD",
    },
  },
  featureList: [
    "Content Creation Acceleration",
    "Cost Optimization",
    "Quality Enhancement",
    "Accessibility Compliance",
    "Content Security",
    "Standards Alignment",
    "Publisher Workflow Automation",
  ],
  applicationSubCategory: "Enterprise Publishing Platform",
  requirements: "Modern web browser",
  image: process.env.REACT_APP_SOCIAL_IMAGE_URL ?? "",
};
