import { isAudioVideoFile, trimTimespan } from "../util/Helpers";
import { ImageAccessibilityResult } from "./ImageResult";
import { Outcome } from "./Outcome";
import { Suggestion } from "./Suggestion";
import { Syllabus } from "./Syllabus";

export interface Upload {
  ownerId: string;
  id: string;
  md5Hash?: string;
  lastUpdated?: Date;

  title?: string;
  description?: string;
  tags?: string[];
  languages?: string[];

  reference?: string;
  citation?: string;
  isbn?: string;
  doi?: string;

  pageIndexOffset?: number;
  storeUrl?: string;
  enabled?: boolean;
  shareWithOrganisation?: boolean;

  authors?: string[];
  publisher?: string;
  publishingYear?: string;

  fileName?: string;
  fileExtension?: string;
  contentType?: string;
  byteSize?: number;
  durationSeconds?: number;
  height?: number;
  width?: number;

  task?: string;
  state?: string;
  step?: string;
  progressPercent?: number;
  message?: string;
}

export interface UploadSlice {
  parentSliceId?: string;
  ownerId?: string;
  uploadId?: string;
  id?: string;
  text?: string;

  start?: string;
  end?: string;

  language?: string;
  title?: string;
  description?: string;

  speakerName?: string;
  speakerId?: string;

  reference?: string;

  tags?: string[];
  image?: string;

  page?: number;
  enabled?: boolean;

  outcomes?: Outcome[];
  alignments?: Suggestion[];
  descriptions?: string[];
  suggestions?: Suggestion[];
  imageAccessibilityResult?: ImageAccessibilityResult;
}

export interface UploadTaskRequest {
  slices?: string[];
  task: string;
  syllabi?: Syllabus[];
  outputLanguage?: string;
}

export const getTaskLabel = (task?: string): string => {
  if (!task) {
    return "Uploaded";
  }
  const taskMap: Record<string, string> = {
    upload: "Uploaded",
    spelling: "Checking Spelling & Grammar",
    align: "Aligning Standards",
    "extract-outcomes": "Extracting Outcomes",
  };
  return taskMap[task] || "Processing";
};

export const getStateLabel = (state: string): string => {
  const stateMap: Record<string, string> = {
    ready: "Queued",
    inProgress: "In Progress",
    complete: "Complete",
    failed: "Failed - Retrying",
    broken: "Error",
  };
  return stateMap[state] || state;
};

export const getUploadSliceLabel = (
  upload: Upload,
  slice: UploadSlice,
): string => {
  if (!slice) {
    return "No Title";
  }

  if (slice.title) {
    return slice.title;
  }

  if (slice.text && isAudioVideoFile(upload.fileExtension)) {
    return "Transcript";
  }

  if (slice.text && slice.text.length > 0) {
    return "Content";
  }

  if (slice.image) {
    return "Image";
  }

  return "No Title";
};

export interface FileUploadRequest {
  file?: File;
}

export interface UploadInterrogation {
  question: string;
}

export const calculateActualPageNumber = (
  sliceData: UploadSlice,
  uploadData: Upload,
): number => {
  return (sliceData.page ?? 0) + (uploadData.pageIndexOffset ?? 0);
};

export const showPage = (
  sliceData: UploadSlice,
  uploadData: Upload,
): string => {
  // Ensure this only works for PDF files
  if (!uploadData.contentType?.includes("pdf")) {
    return "";
  }

  return `Page ${calculateActualPageNumber(sliceData, uploadData)}`;
};

export const showTranscriptData = (
  sliceData: UploadSlice,
  uploadData: Upload,
): string => {
  if (!isAudioVideoFile(uploadData.fileExtension)) {
    return "";
  }

  if (!sliceData) {
    return "";
  }

  if (!uploadData) {
    return "";
  }

  if (!sliceData.start || !sliceData.end) {
    return "";
  }

  return ` | ${trimTimespan(sliceData.start)} - ${trimTimespan(sliceData.end)}${sliceData.speakerName ? ` (${sliceData.speakerName})` : ""}`;
};
